import "./../base-site.js"
$(function() {
    var site = new Site();
    site.init();
});
class Site {
    init() {
        var self = this;
        self.applyBindings();
    }
    applyBindings() {
        var self = this;

        $('.frame-resize').click(function() {
        	let size = $(this).attr('data-size');
        	$('#demo-frame').attr('data-width', size);
        	$('#frame-wrap').attr('data-type', size);
        	$('.frame-resize').removeClass('active');
        	$(this).addClass('active');
        });

        $('#theme-select').change(function() {
        	var theme = $(this).val();
        	$('#demo-frame').attr('src', '');
        	window.history.pushState("", "", '/demo/' + theme);

        	$('#demo-frame').fadeOut('fast', function() {
        		$('#demo-frame').attr('src', 'https://' + theme + '.feelbettr.com');
        		$('#frame-spinner').show();
        		setTimeout(function() {
        			$('#frame-spinner').hide();
        			$('#demo-frame').show();
        		}, 1500);
        	});
        	
        });

        $('[data-action="remove-frame"]').click(function() {
        	var theme = $('#theme-select').val();
        	window.location.href="https://" +theme + ".feelbettr.com";
        });

        let current = window.feelbettr.currentUrl;
                let root = window.feelbettr.profileUrl;
                let page = current.replace(root, '');

        $('.language-selector').val(window.feelbettr.locale);
        $('.language-selector').change(function() {
            let language = $(this).val();
            if(window.feelbettr.locale !== "en") {
                let languageReplace = '/' + language + '/'; 

                if(language == "en") {
                    languageReplace = "/";
                }

                let page = window.feelbettr.currentUrl;
                let parts = window.feelbettr.baseUrl + '/' + window.feelbettr.locale;

                let target = '/' + window.feelbettr.locale + '/';
                if(page === parts) {
                    target = '/' + window.feelbettr.locale;
                } 

                page = page.replace(target, languageReplace);
                location.href = page;

            } else {
                let current = window.feelbettr.currentUrl;
                let root = window.feelbettr.profileUrl;
                let page = current.replace(root, '');
                location.href = root + '/' + language + page;
            }
        });

        // Clean up icons
        $('.uk-icon').each(function() {
            let $svg = $(this).find('svg');
            if($svg.length > 1) {
                $svg.last().remove();
            }
        });

        // $("a").click(function(e) {
        //     e.preventDefault();
        //     var aid = $(this).attr("href");
        //     $('html,body').animate({scrollTop: $(aid).offset().top - 100});
        // });

        $('.toggle-section').on("click", function () {
            let current = $(this).text();
            let newState = $(this).attr('data-text');

            $(this).attr('data-text', current);
            $(this).text(newState);
        });
    }
}   